import '../App.css';
import Card from './Card'
import Ignorer from './Ignorer'
import { useState, useEffect } from 'react'

function App() {

	const [current, setCurrent] = useState('') 

	useEffect(() => {
		applylight()
	}, [])

	const applylight = () => {
		setTimeout(function(){
			const final = document.querySelectorAll(':checked').length > 0 ? 'Light' : 'Dark'
			setCurrent(final)	
		}, 5)
	}
	

	const cards = [
	{
		title: "Jady Nekena main's website",
		url: "https://jadynekena.com",
		logo: "https://jadynekena.com/favicon.ico"
	},
	{
		title: "JsonVizz",
		url: "https://jsonvizz.jadynekena.com",
		logo: "https://jsonvizz.jadynekena.com/favicon.ico"
	},
	{
		title: "Data Addict",
		url: "https://data-addict-com.github.io/",
		logo: "https://data-addict-com.github.io/assets/favicons/apple-touch-icon.png"
	},
	{
		title: "Amazon Best Sellers Website",
		url: "https://amazonbestsellers.org",
		logo: "https://app.amazonbestsellers.org/logo.png"
	},
	{
		title: "Amazon Best Sellers Website (from jadynekena.com)",
		url: "https://amazon.jadynekena.com",
		logo: "https://app.amazonbestsellers.org/logo.png"
	},
	{
		title: "Amazon Best Sellers APP",
		url: "https://app.amazonbestsellers.org",
		logo: "https://app.amazonbestsellers.org/logo.png"
	},
	{
		title: "Apps Status",
		url: "https://status.jadynekena.com",
		logo: "https://betteruptime.com/assets/status_pages/operational_large-70db80ac03aa8b48a624a0b0362b94c81b5813cb1da919c3a7cf8ae09cc550a5.png"
	},


	
	]
  
	return <div className="App">
  			<h1 className="title">Jady Nekena's apps</h1>
  			<label onClick={applylight} htmlFor="dark">{current}</label>
  			<div className="cards-wrap">
  				{cards.map(e => {
  					return <Card title={e['title']} key={e['url']} url={e['url']} logo={e['logo']} />
  				})}
  				
  			</div>
  			<Ignorer/>
  			</div>
}

export default App;
