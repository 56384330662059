import { useState, useEffect } from 'react'

export default function Ignorer(){
	const [ip, setIp] = useState(window.sessionStorage.getItem('ip') || '')
	const spbseLink = 'https://kxdokvtvscvjuwzgmvei.supabase.co/rest/v1/ip_ignore?columns=%22adresse_ip%22%2C%22ville%22%2C%22url_page_source%22'

	const ignoreIP = async () => {
		//get user IP
		const res = await fetch('https://ipapi.co/json/')
							.then(e => e.json())
							.then(data => data)

		const userip = res['ip']
		const ville = res['city']
		const urlpage = window.location.href

		//store it as state
		setIp(ip)

		//store it for session
		window.sessionStorage.setItem('ip',userip)
		
		//send it on supabase
		await fetch(spbseLink,{
			"method": 'POST',
			"headers": {
				"Content-Type": "application/json",
				"apikey": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imt4ZG9rdnR2c2N2anV3emdtdmVpIiwicm9sZSI6ImFub24iLCJpYXQiOjE2NTU2MTk3MTQsImV4cCI6MTk3MTE5NTcxNH0.ViTcu3L79EkuvGvyDiSqwdpgJ0MQFbg8nL1vO0tTcDk",
			},
			"body": "[{\"adresse_ip\":\""+userip+"\",\"ville\":\""+ville+"\",\"url_page_source\":\""+urlpage+"\"}]",
    		"mode": "cors"
		})

	}

	useEffect(() => {
		ignoreIP()	
	})

	return <div className="ignore">
			<h3>Your IP address:</h3>
			<p>{ip}</p>
			</div>
}
